import { ConfigMap, FormatConfig } from '@/types/settings';
import { ColDef } from 'ag-grid-community';
import { ColDefField } from 'ag-grid-community/dist/lib/entities/colDef';
import { Column } from '@tableau/extensions-api-types';
import { Calculation } from '@/types/mapping';

export function isCalculation(column: Column | Calculation | ColDef): column is Calculation {
  if (column) {
    return 'index' in column && typeof column.index === 'string';
  }
  return false;
}

export function getField(column: Column | Calculation | ColDef): any {
  // calculations get a unique calc_<timestamp> as index so we can just use that...
  // for fieldName we replace dots with underscores so ag-grid will not misinterpret them as
  // deep values, see: https://github.com/ag-grid/ag-grid/blob/master/packages/ag-grid-community/src/ts/utils.ts#L134
  // update: although no longer required, we have to keep doing this for now since column layout depends on the name
  if (isCalculation(column)) {
    return 'index' in column && column.index;
  }

  if ('fieldName' in column) {
    return column.fieldName.replace(/\./g, '_');
  } else if ('field' in column && column.field) {
    let field: ColDefField = column.field;
    return field.replace(/\./g, '_');
  }
}

/* Column Groups Functions */
export function getColumnGroups(columnConfig: Object, columnGroupConfig: Object): string[] {
  const columnConfigList = Object.entries(columnConfig).map(([key, value]) => ({ key, ...value }));

  const groupHeaders: string[] = [];
  columnConfigList.forEach((column) => {
    if ('groupHeader' in column && column['groupHeader'] !== null) {
      groupHeaders.push(column['groupHeader']);
    }
  });
  // Get groups that dont have columns from the group config
  Object.keys(columnGroupConfig).forEach((columnConfigName) => {
    groupHeaders.push(columnConfigName);
  });
  return [...new Set(groupHeaders)];
}

export function getColumnsInGroup(columns: Array<Column>, columnsConfig: ConfigMap, groupName: string) {
  return columns.filter((column) => {
    return isCalculation(column)
      ? columnsConfig[column?.index]?.groupHeader === groupName
      : columnsConfig.hasOwnProperty(column?.fieldName) && columnsConfig[column?.fieldName]?.groupHeader === groupName;
  });
}

export function removeColumnFromGroup(columnField: String | number, dispatch: (action: any) => void) {
  dispatch({
    type: 'updateColumnConfig',
    index: columnField,
    change: { groupHeader: null },
  });
}

const BETWEEN_BRACKETS = /\((.*)\)$/;

export function getHeader(column: Column | Calculation) {
  if (isMeasure(column)) {
    let matches = column.fieldName.match(BETWEEN_BRACKETS);
    if (matches) {
      return matches[1];
    }
  }
  return column.fieldName;
}
/**
 * @todo: this should use field role
 * There are six pre-defined cell data types: 'text', 'number', 'boolean', 'date', 'dateString' and 'object'.
 * https://www.ag-grid.com/javascript-data-grid/cell-data-types/
 */
export function isMeasure(column: Column | Calculation | ColDef): boolean {
  const dataType = 'dataType' in column ? column.dataType : false;

  switch (dataType) {
    case 'int':
    case 'float':
      return true;
    default:
      return false;
  }
}

export function isDate(column: Column | Calculation): boolean {
  switch (column.dataType) {
    case 'date':
    case 'date-time':
      return true;
    default:
      return false;
  }
}

// replaces spaces, periods, and commas in a given string with underscores
export function removeSpaces(string: string) {
  return string.replace(/[ .,]/g, (match) => (match === ' ' ? '_' : ''));
}

export function isValueEmpty(value: undefined | null | string | number) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && !value.trim()) ||
    value === '%null%' ||
    (typeof value === 'number' && (Number.isNaN(value) || value === Infinity || value === -Infinity))
  );
}
/**
 * getFractionDigits gets the desired number of fraction digits from the config.
 * For floats the default is 2 fraction digits.
 * Other values are always displayed without fraction digits.
 */
export function getFractionDigits(column: Column | Calculation, config: FormatConfig): number {
  // :maximumFractionDigitsDefaultValue
  if (column.dataType === 'float') {
    let fractionDigits = config.maximumFractionDigits;
    if (fractionDigits === undefined || fractionDigits === '') {
      return 2;
    }
    return parseInt(fractionDigits);
  }
  return 0;
}
