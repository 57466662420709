import { cn } from '@/utils/cn';
import {
  LiaEqualsSolid,
  LiaGreaterThanEqualSolid,
  LiaGreaterThanSolid,
  LiaLessThanEqualSolid,
  LiaLessThanSolid,
  LiaMinusSolid,
  LiaNotEqualSolid,
  LiaPlusSolid,
  LiaSlashSolid,
} from 'react-icons/lia';
import { RiParenthesesLine } from 'react-icons/ri';
import { VscChromeClose } from 'react-icons/vsc';

export default function ({
  selectedText,
  type = '',
}: {
  selectedText: (symbol: string) => void;
  type?: string;
}): JSX.Element {
  return (
    <div className="mx-auto flex w-fit flex-wrap items-center gap-2">
      {operators.map((op: Operator) => {
        if (type !== 'if' && IfElseOperator.includes(op.name)) {
          return null;
        }
        return (
          <div
            className={cn(
              'grid cursor-pointer place-items-center border-2 border-gray-300 hover:bg-gray-100',
              op.name === 'and' || op.name === 'or' ? 'p-1' : 'p-2 text-lg'
            )}
            onMouseDown={() => selectedText(op.symbol)}
            key={op.name}>
            {op.icon}
          </div>
        );
      })}
    </div>
  );
}

const IfElseOperator = [
  'equal to',
  'not equal to',
  'greater than',
  'less than',
  'less than or equal to',
  'greater than or equal to',
  'and',
  'or',
];

type Operator = {
  name: string;
  symbol: string;
  icon: JSX.Element | string;
};

const iconSize = 14;

const operators = [
  {
    name: 'add',
    symbol: '+',
    icon: <LiaPlusSolid size={iconSize} />,
  },
  {
    name: 'subtract',
    symbol: '-',
    icon: <LiaMinusSolid size={iconSize} />,
  },
  {
    name: 'multiply',
    symbol: '*',
    icon: <VscChromeClose size={iconSize} />,
  },
  {
    name: 'divide',
    symbol: '/',
    icon: <LiaSlashSolid size={iconSize} />,
  },
  {
    name: 'equal to',
    symbol: '===',
    icon: <LiaEqualsSolid size={iconSize} />,
  },
  {
    name: 'not equal to',
    symbol: '!==',
    icon: <LiaNotEqualSolid size={iconSize} />,
  },
  {
    name: 'greater than',
    symbol: '>',
    icon: <LiaGreaterThanSolid size={iconSize} />,
  },
  {
    name: 'less than',
    symbol: '<',
    icon: <LiaLessThanSolid size={iconSize} />,
  },
  {
    name: 'greater than or equal to',
    symbol: '>=',
    icon: <LiaGreaterThanEqualSolid size={iconSize} />,
  },
  {
    name: 'less than or equal to',
    symbol: '<=',
    icon: <LiaLessThanEqualSolid size={iconSize} />,
  },
  {
    name: 'paranthesis',
    symbol: '()',
    icon: <RiParenthesesLine size={iconSize} />,
  },
  {
    name: 'Absolute',
    symbol: 'ABS()',
    icon: 'ABS',
  },
  {
    name: 'and',
    symbol: '&&',
    icon: 'AND',
  },
  {
    name: 'or',
    symbol: '||',
    icon: 'OR',
  },
];
