import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@utils/cn';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { GoInfo } from 'react-icons/go';

const labelVariants = cva('peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-karla', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      base: 'text-base',
      default: 'text-lg',
      lg: 'text-lg',
      xl: 'text-xl',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

interface LabelProps extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  size?: VariantProps<typeof labelVariants>['size'];
}

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, size, ...props }, ref) => (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(labelVariants({ size }), className)}
      {...props}
    />
  )
);
Label.displayName = LabelPrimitive.Root.displayName;

interface LabelWithTooltipProps extends React.ComponentPropsWithoutRef<typeof Label> {
  tooltip?: React.ReactNode;
  size?: VariantProps<typeof labelVariants>['size'];
  disabled?: boolean;
  tooltipClassName?: string;
}

const LabelWithTooltip = React.forwardRef<React.ElementRef<typeof Label>, LabelWithTooltipProps>(
  ({ tooltip, className, size, disabled, tooltipClassName, ...props }, ref) => (
    <TooltipProvider>
      <div className="flex items-center space-x-2">
        <Label
          ref={ref}
          className={cn({ 'cursor-not-allowed opacity-50': disabled }, className)}
          size={size}
          {...props}
        />
        {tooltip && (
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <div className="font-lg text-secondary">
                <GoInfo
                  className="cursor-pointer"
                  size={13}
                />
              </div>
            </TooltipTrigger>
            <TooltipContent
              side="top"
              align="center"
              className={cn(
                'm-4 mx-8 max-w-4xl break-words bg-secondary font-karla text-lg text-white',
                tooltipClassName
              )}>
              {typeof tooltip === 'string' ? <p>{tooltip}</p> : tooltip}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </TooltipProvider>
  )
);
LabelWithTooltip.displayName = 'LabelWithTooltip';

export { Label, LabelWithTooltip };
